import React, { Fragment, useEffect, useState, useRef } from 'react';
import './index.scss';
import ArcText from 'react-arc-text-fix';
import { useLocation } from 'react-router-dom';
import ftlogo from "../../logo.png";

const pages = ["Professor", "Teacher", "Student", "Player"];
const professor_class_fm = ['Start', 'Create Class', 'Invite Students', 'Monitor Game Perf', 'Monitor Learning', 'Grade the Class', 'Stop'];
const professor_contest_fm = ['Start', 'Create Contest', 'Invite Participants', 'Grade the Contest', 'Game Performance', 'Stop'];
const player_contest_fm = ['Start', 'Create Contest', 'Invite Participants', 'Close the Contest', 'Game Performance', 'Stop'];
const student_class_fm = ['Start', 'Registration', 'Login Process ', 'Game Performance', 'Monitor Learning', 'Dashboard', 'Stop'];
const create_class_list_img = ['class-1', 'class-1.2', 'class-1.3'];
const invite_class_list_img = ['class-invite-2', 'class-invite-2.1'];
const class_closeout_list_img = ['classdashboard-2.png'];
const contest_invite_student_img = ['contestdashboard-2.png']

export default () => {
    const [activePage, togglePage] = useState();

    let page = useLocation();
    let val = document.getElementById(page['hash'].substring(1));

    useEffect(() => {
        if (val !== null) {
            val.scrollIntoView();
        }
    }, [val])

    useEffect(() => {
        let currentPage = page['search'].split('=')[1]
        togglePage(currentPage)
    }, [page])

    const _renderProfessorClassFm = () => {
        return <div className="fm">
            {
                professor_class_fm.map((item, i) => {
                    if (i === 0) {
                        return <div style={{ position: 'relative', width: 55, height: 45, marginRight: 6, top: -5 }}>
                            <img key={i} style={{ width: 55, height: 55, opacity: 0.8 }} src={require('./src/images/circle_black.png')} />
                            <div style={{ width: 55, height: 55, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, color: '#D1FCF7' }}><a style={{ color: 'white' }} href="#">{item}</a></div>
                        </div>
                    } else if (i === 1) {
                        return <div style={{ position: 'relative', width: 150, height: 45 }}>
                            <img key={i} style={{ width: 150, height: 45 }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><a href="#CreateClass">{item}</a></div>
                        </div>
                    } else if (i === 2) {
                        return <div style={{ position: 'relative', width: 150, height: 160 }}>
                            <img key={i} style={{ width: 100, height: 160 }} src={require('./src/images/Path_3.png')} />
                            <div style={{ position: 'absolute', top: 12, left: 59, display: 'flex', justifyContent: 'center', alignItems: 'center', transform: 'rotate(90deg) scale(0.45)' }}><a href="#InviteStudents"><ArcText class={"arch_text1"} text={item} /></a></div>
                        </div>
                    } else if (i === 3) {
                        return <div style={{ position: 'relative', width: 150, height: 160, top: -47, left: -40 }}>
                            <img key={i} style={{ width: 100, height: 160, transform: 'rotateY(180deg)' }} src={require('./src/images/Path_3.png')} />
                            <div style={{ position: 'absolute', top: 10, left: 46, display: 'flex', justifyContent: 'center', alignItems: 'center', transform: 'rotate(270deg) scale(0.45)' }}><a href="#MonitorGamePerformance"><ArcText class={"arch_text1"} text={item} /></a></div>
                        </div>
                    }
                    else if (i === 4) {
                        return <div style={{ position: 'relative', width: 150, height: 45, left: -95, top: -47 }}>
                            <img key={i} style={{ width: 150, height: 45, transform: 'rotateZ(180deg)' }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><a href="#MoniterStudentslearning">{item}</a></div>
                        </div>
                    }
                    else if (i === 5) {
                        return <div style={{ position: 'relative', width: 150, height: 45, top: -95, left: 65 }}>
                            <img key={i} style={{ width: 150, height: 45 }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><a href="#CloseouttheClass">{item}</a></div>
                        </div>
                    }
                    else if (i === 6) {
                        return <div style={{ position: 'relative', width: 55, height: 45, top: -100, left: 75, marginRight: 6 }}>
                            <img key={i} style={{ width: 55, height: 55, transform: 'rotateZ(180deg)', opacity: 0.8 }} src={require('./src/images/circle_black.png')} />
                            <div style={{ width: 55, height: 55, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, color: '#D1FCF7' }}><a style={{ color: 'white' }} href="#">{item}</a></div>
                        </div>
                    }
                })
            }
        </div>
    }

    const _renderProfessorContestFm = () => {
        return <div className="fm">
            {
                professor_contest_fm.map((item, i) => {
                    if (i === 0) {
                        return <div style={{ position: 'relative', width: 55, height: 45, marginRight: 6, top: -5 }}>
                            <img key={i} style={{ width: 55, height: 55, opacity: 0.8 }} src={require('./src/images/circle_black.png')} />
                            <div style={{ width: 55, height: 55, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, color: '#D1FCF7' }}><a style={{ color: 'white' }} href="#">{item}</a></div>
                        </div>
                    } else if (i === 1) {
                        return <div style={{ position: 'relative', width: 150, height: 45 }}>
                            <img key={i} style={{ width: 150, height: 45 }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><a href="#CreateContest">{item}</a></div>
                        </div>
                    } else if (i === 2) {
                        return <div style={{ position: 'relative', width: 150, height: 160 }}>
                            <img key={i} style={{ width: 100, height: 160 }} src={require('./src/images/Path_3.png')} />
                            <div style={{ position: 'absolute', top: -10, left: 59, display: 'flex', justifyContent: 'center', alignItems: 'center', transform: 'rotate(90deg) scale(0.45)' }}><a href="#InviteStudentsContest"><ArcText class={"arch_text1"} text={item} /></a></div>
                        </div>
                    } else if (i === 3) {
                        return <div style={{ position: 'relative', width: 150, height: 160, top: -47, left: -40 }}>
                            <img key={i} style={{ width: 100, height: 160, transform: 'rotateY(180deg)' }} src={require('./src/images/Path_3.png')} />
                            <div style={{ position: 'absolute', top: 2, left: 42, display: 'flex', justifyContent: 'center', alignItems: 'center', transform: 'rotate(270deg) scale(0.45)' }}><a href="#CloseouttheContest"><ArcText class={"arch_text1"} text={item} /></a></div>
                        </div>
                    }
                    else if (i === 4) {
                        return <div style={{ position: 'relative', width: 150, height: 45, top: -45, left: -95 }}>
                            <img key={i} style={{ width: 150, height: 45, transform: 'rotateZ(180deg)' }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><a href="#MonitorGamePerformanceContest">{item}</a></div>
                        </div>
                    }
                    else if (i === 5) {
                        return <div style={{ position: 'relative', width: 55, height: 45, top: 62, left: -232, marginRight: 6 }}>
                            <img key={i} style={{ width: 55, height: 55, transform: 'rotateZ(180deg)', opacity: 0.8 }} src={require('./src/images/circle_black.png')} />
                            <div style={{ width: 55, height: 55, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, color: '#D1FCF7' }}><a style={{ color: 'white' }} href="#">{item}</a></div>
                        </div>
                    }
                })
            }
        </div>
    }

    const _renderProfessor = () => {
        return <Fragment>
            <label style={{ backgroundColor: '#1A73E8', color: 'white', padding: '10px 6px' }} className="title">Professors Guide</label>

            <section id="Class">
                <p>
                    Quickly increase student engagement and excitement in your class by leveraging our innovative and seamless learning platform and trading simulation. In less than five minutes, you can complete registration and set up your first class. Then your students can quickly learn by experiencing the roles of a trader and market maker!
                </p>
                <p>
                    <label style={{ fontWeight: 'bold' }}>FutureTraders</label> simulation enhances finance and investment courses by helping professors and students achieve their goals in a fun and engaging environment. Our platform offers a multi-dimensional learning experience that exposes students to complex real-world economic scenarios and helps them master these concepts through scaffolded competitive gameplay.
                </p>
                <p><strong>Welcome Professors!</strong> You have arrived at the gateway to master FutureTraders.</p>
                <h2 style={{fontWeight:'bold', opacity:0.8, marginTop:20}}>Class</h2>
                <div style={{height:1, border:'0.1px solid rgba(0,0,0,0.2)', width:'100%', marginBottom:10}}></div>
                <p>The following interactive model depicts a simple flow of interactions that a professor uses to integrate FutureTraders into any class and click on any component of the model to navigate to the related topic:</p>
                {_renderProfessorClassFm()}
            </section>


            <section id="CreateClass">
                <p>The sections below explain each interaction in detail, including instructions, best practices, and suggestions:</p>
                <label className="title">Create a Class</label>
                <p></p>
                <ol>
                    <li>1) Register as a professor on the home page.</li>
                    <li>2) You will be directed to  “Create a Class”  (click link)</li>
                    <li>3) You will be directed to Class Registration to input the following information:</li>
                </ol>
                <label className="title">Screenshots/steps/guidance</label>
                {
                    create_class_list_img.map((img, i) => {
                        return <Fragment>
                            {
                                i === 0 ?
                                    <Fragment>
                                        <p></p>
                                        <ul className="guide_li">
                                            <li>Class Name: Name your class. (i.e., FIN220 or Wall Street 101)</li>
                                            <li>Class Subject: Choose from several options in the dropdown.</li>
                                            <li>Description: Describe your class. (i.e., Investing or Portfolio Management)</li>
                                            <li>Time Zone: This is generally automated for everyone.</li>
                                            <li>Number of Students: The maximum limit of students who might enroll. </li>
                                            <li>Academic Level: Choose the level (i.e., Undergrad, Graduate, etc) </li>
                                            <li>Select Game Types: Stocks and/or Bonds </li>
                                        </ul>
                                    </Fragment> :
                                    i === 1 ?
                                        <Fragment>
                                            <p>After entering all the details in the Class Information section, click NEXT to enter the curriculum configuration registration and enter the following:</p>
                                            <ul className="guide_li">
                                                <li>Registration Start Date: This can be today's date or the earliest date to allow student registration. </li>
                                                <li>Registration End Date: The final deadline/date for student registration.</li>
                                                <li>Trading Start Date: The earliest date to allow students to begin playing Future Traders. </li>
                                                <li>Trading End Date: The final date to allow students to play Future Traders. </li>
                                                <li>Passing %: Minimum Percentage for students to pass the quiz and receive a trading license. (i.e., 70%) </li>
                                                <li>Number of Attempts: Number of retries to pass the quiz (i.e., 5)</li>
                                                <li>Amount Awarded (Per Question): The amount of play money awarded for each correct answer: (i.e., $100, so if a student answers 8 correct questions, they have $800 to play with) </li>
                                            </ul>
                                        </Fragment> :
                                        <Fragment>
                                            <p>After completing the curriculum configuration section, click NEXT to complete the Game Configuration registration and enter the following:</p>
                                            <ul className="guide_li">
                                                <li>Rounds at Each Level: Choose how many two-minute rounds you would like the students to play at each difficulty level: We suggest 3 to 5 rounds.</li>
                                                <li>Minimum Balance Benchmark: Set a minimum balance required for a student to play. If a student fails to maintain the minimum balance, he/she will have to retake a quiz to regain his/her trading license.</li>
                                                
                                            </ul>
                                        </Fragment>
                            }
                            <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/' + img + '.PNG')} />
                        </Fragment>
                    })
                }
            </section>

            <section id="InviteStudents">
                <label className="title">Invite Students</label>
                <p>Your link(class) is created, now you need to invite students for registering, here we have two ways to invite</p>
                <label className="title">Screenshots/steps/guidance</label>
                {
                    invite_class_list_img.map((img, i) => {
                        return <Fragment>
                            {
                                i === 0 ?
                                    <ul className="guide_li"><li>After completion of the form, You can use the popup box and enter multiple email addresses to send an invitation.</li></ul> :
                                    <ul className="guide_li"><li>There is also another option where you can copy the URL link and click on the edit icon of your portal and you can paste and invite students. This can be easily used as a link in Blackboard or whichever educational platform you use.</li></ul>
                            }
                            <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/' + img + '.PNG')} />
                        </Fragment>
                    })
                }
            </section>

            <section id="MoniterStudentslearning">
                <label className="title">Monitor Students Learning</label>
                <p>You can check the progress of your classes by observing how many students attend the course, and their progress through a pie chart. You can see the Top 5 students, Coursework Results, Game Results, and Average Performance in line graphs, column graphs, and pie charts. </p>
                <label className="title">Screenshots/steps/guidance</label>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/classdashboard-1.png')} />
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/professor/Coursework-class.png')} />
            </section>

            <section id="MonitorGamePerformance">
                <label className="title">Monitor Game Performance</label>
                <p>The Professor is required to keep a track of his students’ work, so this feature “Course Work Performance” acts as a helping hand to the professor by presenting the pupil’s score of the quiz which they took part In, this result box consists of four aspects.
                
                </p>
                <ul className="guide_li">
                    <li>Player-This attribute presents the name or the user Id of the player</li>
                    <li>Score-The students percentage quiz score is shown here</li>
                    <li>Passing %- At the time of class creation, the professor needs to set a standard percent, so that is shown here.</li>
                    <li>Status: The status of the course appears as “In Progress” till the end date, once the course is finished it appears as “Completed”.</li>
                </ul>
                <label className="title">Screenshots/steps/guidance</label>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/classdashboard-1.png')} />
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/professor/Gameperf-class.png')} />
             
            </section>

            <section id="CloseouttheClass">
                <label className="title">Grade the Class</label>
                <p>
                    Report Card:
                    The Report card is another sub-feature in “Course Work Performance” where the professor can see students' quiz details such as the topic, Level they have reached, percentage score,  Pass or fail, and the Amount they have earned. For this feature, you just need to click on the name of the student in the Course Work Performance box.
                </p>
                {
                    class_closeout_list_img.map((img, i) => {
                        // return <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/' + img)} />
                        
                    })
                    
                }
                 <img style={{ marginBottom: 20, marginLeft: '20%' }} src={require('./src/images/professor/Report-card-game.png')} />
            </section>
            <h2 style={{fontWeight:'bold', opacity:0.8, marginTop:20}}>Contest</h2>
                <div style={{height:1, border:'0.1px solid rgba(0,0,0,0.2)', width:'100%', marginBottom:10}}></div>
            <section id="Contest">
                <p>The following model depicts a simple flow of interactions that a professor uses to work with FutureTraders Contest:</p>
                {_renderProfessorContestFm()}
            </section>

            <section id="CreateContest">
                <p>The sections below explain each interaction in detail, including instructions, best practices, and suggestions:</p>
                <label className="title">Create a Contest</label>
                <ul className="guide_li">
                    <li>Contest Name: Name your contest.</li>
                    <li>Contest Subject: Choose from several options in the dropdown.</li>
                    <li>Description: Describe your contest. (i.e., Investing or Portfolio Management)</li>
                    <li>Registration Start date: This can be today's date or the earliest date to allow student registration.</li>
                    <li>Registration End Date: The final deadline/date for student registration.</li>
                    <li>Time zone: This is automated for everyone.</li>
                    <li>Number of Participants: The maximum limit of participants who might enroll.</li>
                     <li>Select Game Types: Stocks and/or Bonds</li>
                    <li>Initial Capital: Initial Balance to start the game</li>
                    <li>Rounds At Each Level: Choose how many 2 minute rounds you would like the students to play at each difficulty level: We suggest 3 to 5 rounds.</li>
                </ul>
                <label className="title">Screenshots/steps/guidance</label>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Contest-1.PNG')} />
            </section>

            <section id="InviteStudentsContest">
                <label className="title">Invite Participants(Hosted)</label>
                <p>Your link(contest) is created and you can invite students to register using either of the following choices:</p>
                <ul className="guide_li">
                    <li>After completion of the form, You can use the popup box and enter multiple email addresses to send an invitation.</li>
                </ul>
                <img style={{  marginLeft: '20%' }} src={require('./src/images/professor/Invite-participants-popup-contest.png')} />

                <ul className="guide_li">
                    <li> There is also another option where you can copy the URL link and click on the edit icon of your portal and you can paste and invite students. This can be easily used as a link in Blackboard or whichever educational platform you use.</li>
                </ul>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/professor/Invite-participants-URL-contest.png')} />

                <label className="title">Invite Participants(Participating)</label>
                <p>Professor and Teacher can also be the Participants of any contest.</p>
                <ul className="guide_li">
                    <li>If the Professor/Teacher/Player receives the invitation mail from the other professors or teachers or players, by clicking on the given link in the mail, he will be directed to the Future Traders webpage with a pop-up message saying “you have successfully joined the contest”.</li>
                    <li>If the Participant gets the URL From other sources, then he should enter the registered mail ID so that he will join the class successfully.</li>
                    <li>Now, by clicking on “My Contests” which is on the top right of the home page, you will be directed to the “Participating Contests” page, where you can find all your contests.</li>
                </ul>
               
                
                <label className="title">Screenshots/steps/guidance</label>
                {
                    contest_invite_student_img.map((img, i) => {
                        // return <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/' + img)} />
                    })
                }
                 <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/professor/Contest-participating.png')} />
            </section>

            <section id="MonitorGamePerformanceContest">
                <label className="title">Monitor Game Performance</label>
                <p>When a player secures the standard score which is fixed by the professor he will be allowed to play the game. </p>
                <p>After the game ends, to perceive  information of the game status you can make use of this feature “Game Performance” </p>
                <p>This attribute consists of three aspects:</p>
                <ul className="guide_li">
                    <li>Player- Here you can see the players’ names.</li>
                    <li>Ending balance-  when a player plays the game, after each round the player Gains or losses a certain amount of money, this attribute helps the player in seeing the end balance.</li>
                    <li>Overall status- This is a feature that is related to the end date of the course.</li>
                </ul>
                
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/contestdashboard-1.png')} />
            </section>

            <section id="CloseouttheContest">
                <label className="title">Grade the Contest</label>
                <p>
                    Report Card:
                    The Report card is another sub-feature in “Course Work Performance” where the professor can see students' quiz details such as the topic, Level they have reached, percentage score,  Pass or fail, and the Amount they have earned. For this feature, you just need to click on the name of the student in the Course Work Performance box.
                </p>
                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
                <label className="title">Screenshots/steps/guidance</label>
                <p><img style={{ width:'55%', marginLeft: '20%' }} src={require('./src/images/professor/Grade-contest.png')} /></p>
                
            </section>

            <p>Our next set of enhancements will enable professors to author/tailor - curriculum, quizzes, and game simulations. We will be the only platform providing such capabilities.</p>
            <p>The goal of this guide is to support you with all your information and how-to needs. If you have any questions or suggestions, please email us at info@playfuturetraders.com.</p>
        </Fragment>
    }

    const _renderTeacherClassFm = () => {
        return <div className="fm">
            {
                professor_class_fm.map((item, i) => {
                    if (i === 0) {
                        return <div style={{ position: 'relative', width: 55, height: 45, marginRight: 6, top: -5 }}>
                            <img key={i} style={{ width: 55, height: 55, opacity: 0.8 }} src={require('./src/images/circle_black.png')} />
                            <div style={{ width: 55, height: 55, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, color: '#D1FCF7', paddingTop: 5 }}><label>{item}</label></div>
                        </div>
                    } else if (i === 1) {
                        return <div style={{ position: 'relative', width: 150, height: 45 }}>
                            <img key={i} style={{ width: 150, height: 45 }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><label>{item}</label></div>
                        </div>
                    } else if (i === 2) {
                        return <div style={{ position: 'relative', width: 150, height: 45 }}>
                            <img key={i} style={{ width: 150, height: 45 }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><label>{item}</label></div>
                        </div>
                    } else if (i === 3) {
                        return <div style={{ position: 'relative', width: 150, height: 160 }}>
                            <img key={i} style={{ width: 100, height: 160 }} src={require('./src/images/Path_3.png')} />
                            <div style={{ position: 'absolute', top: 12, left: 59, display: 'flex', justifyContent: 'center', alignItems: 'center', transform: 'rotate(90deg) scale(0.45)' }}><ArcText class={"arch_text1"} text={item} /></div>
                        </div>
                    } else if (i === 4) {
                        return <div style={{ position: 'relative', width: 55, height: 45, top: -50, marginRight: 6 }}>
                            <img key={i} style={{ width: 55, height: 55, transform: 'rotateZ(180deg)', opacity: 0.8 }} src={require('./src/images/circle_black.png')} />
                            <div style={{ width: 55, height: 55, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, color: '#D1FCF7', paddingTop: 5 }}><label>{item}</label></div>
                        </div>
                    } else if (i === 5) {
                        return <div style={{ position: 'relative', width: 150, height: 45, top: -45 }}>
                            <img key={i} style={{ width: 150, height: 45, transform: 'rotateZ(180deg)' }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><label>{item}</label></div>
                        </div>
                    } else if (i === 6) {
                        return <div style={{ position: 'relative', width: 150, height: 45, top: -45 }}>
                            <img key={i} style={{ width: 150, height: 45, transform: 'rotateZ(180deg)' }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><label>{item}</label></div>
                        </div>
                    }
                })
            }
        </div>
    }

    const _renderTeacherContestFm = () => {
        return <div className="fm">
            {
                professor_contest_fm.map((item, i) => {
                    if (i === 0) {
                        return <div style={{ position: 'relative', width: 55, height: 45, marginRight: 6, top: -5 }}>
                            <img key={i} style={{ width: 55, height: 55, opacity: 0.8 }} src={require('./src/images/circle_black.png')} />
                            <div style={{ width: 55, height: 55, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, color: '#D1FCF7', paddingTop: 5 }}><label>{item}</label></div>
                        </div>
                    } else if (i === 1) {
                        return <div style={{ position: 'relative', width: 150, height: 45 }}>
                            <img key={i} style={{ width: 150, height: 45 }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><label>{item}</label></div>
                        </div>
                    } else if (i === 2) {
                        return <div style={{ position: 'relative', width: 150, height: 45 }}>
                            <img key={i} style={{ width: 150, height: 45 }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><label>{item}</label></div>
                        </div>
                    } else if (i === 3) {
                        return <div style={{ position: 'relative', width: 150, height: 160 }}>
                            <img key={i} style={{ width: 100, height: 160 }} src={require('./src/images/Path_3.png')} />
                            <div style={{ position: 'absolute', top: 12, left: 59, display: 'flex', justifyContent: 'center', alignItems: 'center', transform: 'rotate(90deg) scale(0.45)' }}><ArcText class={"arch_text1"} text={item} /></div>
                        </div>
                    } else if (i === 4) {
                        return <div style={{ position: 'relative', width: 150, height: 45, top: -45, opacity: 0 }}>
                            <img key={i} style={{ width: 150, height: 45, transform: 'rotateZ(180deg)' }} src={require('./src/images/Path_4.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><label>{item}</label></div>
                        </div>
                    } else if (i === 5) {
                        return <div style={{ position: 'relative', width: 55, height: 45, top: -50, marginRight: 6 }}>
                            <img key={i} style={{ width: 55, height: 55, transform: 'rotateZ(180deg)', opacity: 0.8 }} src={require('./src/images/circle_black.png')} />
                            <div style={{ width: 55, height: 55, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, color: '#D1FCF7', paddingTop: 5 }}><label>{item}</label></div>
                        </div>
                    } else if (i === 6) {
                        return <div style={{ position: 'relative', width: 150, height: 45, top: -45 }}>
                            <img key={i} style={{ width: 150, height: 45, transform: 'rotateZ(180deg)' }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><label>{item}</label></div>
                        </div>
                    }
                })
            }
        </div>
    }

    const _renderTeacher = () => {
        return <Fragment>
            <label style={{ backgroundColor: '#1A73E8', color: 'white', padding: '10px 6px' }} className="title">Teachers Guide</label>

            <section id="Class">
                <p>
                Quickly increase student engagement and excitement in your class by leveraging our innovative and seamless learning platform and trading simulation. In less than five minutes you can complete registration and set up your first class. Then your students can quickly learn by experiencing the roles of a trader and market maker!                </p>
                <p>
                    <label style={{ fontWeight: 'bold' }}>FutureTraders</label> simulation enhances finance and investment courses by helping professors and students achieve their goals in a fun and engaging environment. Our platform offers a multi-dimensional learning experience that exposes students to complex real-world economic scenarios and helps them master these concepts through scaffolded competitive gameplay.
                </p>
                <p><strong>Welcome Teachers!</strong> You have arrived at the gateway to master FutureTraders.</p>
                <h2 style={{fontWeight:'bold', opacity:0.8, marginTop:20}}>Class</h2>
                <div style={{height:1, border:'0.1px solid rgba(0,0,0,0.2)', width:'100%', marginBottom:10}}></div>
                <p>The following interactive model depicts a simple flow of interactions that a professor uses to integrate FutureTraders into any Class, click on any component of the model to navigate to the related topic:</p>
                {_renderProfessorClassFm()}
            </section>

            <section id="CreateClass">
                <p>The sections below explain each interaction in detail, including instructions, best practices, and suggestions:</p>
                <label className="title">Create a Class</label>
                <p> </p>
                <ol>
                    <li>1) Register as a professor on the home page.</li>
                    <li>2) You will be directed to  “Create a Class”  (click link)</li>
                    <li>3) You will be directed to Class Registration to input the following information:</li>
                </ol>
                <label className="title">Screenshots/steps/guidance</label>
                {
                    create_class_list_img.map((img, i) => {
                        return <Fragment>
                            {
                                i === 0 ?
                                    <Fragment>
                                        <p></p>
                                        <ul className="guide_li">
                                            <li>Class Name: Name your class. (i.e., FIN220 or Wall Street 101)</li>
                                            <li>Class Subject: Choose from several options in the dropdown.</li>
                                            <li>Description: Describe your class. (i.e., Investing or Portfolio Management)</li>
                                            <li>Time Zone: This is generally automated for everyone.</li>
                                            <li>Number of Students: The maximum limit of students who might enroll. </li>
                                            <li>Academic Level: Choose the level (i.e., Undergrad, Graduate, etc) </li>
                                            <li>Select Game Types: Stocks and/or Bonds </li>
                                        </ul>
                                    </Fragment> :
                                    i === 1 ?
                                        <Fragment>
                                            <p>After entering all the details in the Class Information section, click  “NEXT” to enter the curriculum configuration registration and enter the following:</p>
                                            <ul className="guide_li">
                                                <li>Registration Start Date: This can be today's date or the earliest date to allow student registration. </li>
                                                <li>Registration End Date: The final deadline/date for student registration.</li>
                                                <li>Trading Start Date: The earliest date to allow students to begin playing Future Traders. </li>
                                                <li>Trading End Date: The final date to allow students to play Future Traders. </li>
                                                <li>Passing %: Minimum Percentage for students to pass the quiz and receive a trading license. (i.e., 70%) </li>
                                                <li>Number of Attempts: Number of retries to pass the quiz (i.e., 5)</li>
                                                <li>Amount Awarded (Per Question): The amount of play money awarded for each correct Quiz answer: (i.e., $100, so if a student answers 8 correct questions they have $800 to play with) </li>
                                            </ul>
                                        </Fragment> :
                                        <Fragment>
                                            <p>After completing the curriculum configuration section, click “NEXT” to complete the Game Configuration registration and enter the following:</p>
                                            <ul className="guide_li">
                                                <li>Rounds at Each Level: Choose how many 2 minute rounds you would like the students to play at each difficulty level: We suggest 3 to 5 rounds.</li>
                                                <li>Minimum Balance Benchmark: Set a minimum balance required for a student to play. If a student goes below the minimum he/she will have to retake a quiz to regain his/her trading license.</li>
                                                
                                            </ul>
                                        </Fragment>
                            }
                            <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/' + img + '.PNG')} />
                        </Fragment>
                    })
                }
            </section>

            <section id="InviteStudents">
                <label className="title">Invite Students</label>
                <p>Your link(class) is created, now you need to invite students for registering, here we have 2 ways to invite</p>
                <label className="title">Screenshots/steps/guidance</label>
                {
                    invite_class_list_img.map((img, i) => {
                        return <Fragment>
                            {
                                i === 0 ?
                                    <ul className="guide_li"><li>After completion of the form, You can use the popup box and enter multiple email addresses to send an invitation.</li></ul> :
                                    <ul className="guide_li"><li>There is also another option where you can copy the URL link and click on the edit icon of your portal and you can paste and invite students. This can be easily used as a link in Blackboard or whichever educational platform you use.</li>
                                    <li>We have an alternate register option for school students, which doesn’t require an email address, they can register with the “User Name”.</li></ul>
                            }
                            <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/' + img + '.PNG')} />
                        </Fragment>
                    })
                }
            </section>

            <section id="MoniterStudentslearning">
                <label className="title">Monitor Students Learning</label>
                <p>You can check the progress of your classes by observing how many students attend the course, and their progress through a pie chart. You can see the Top 5 students, Coursework Results, Game Results, and Average Performance in line graphs, column graphs, and pie charts.
                </p>
                <label className="title">Screenshots/steps/guidance</label>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/classdashboard-1.png')} />
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/professor/Coursework-class.png')} />
            </section>

            <section id="MonitorGamePerformance">
                <label className="title">Monitor Game Performance</label>
                <p>The Teacher is required to keep a track of his students’ work, so this feature “Course Work Performance” acts as a helping hand to the professor by presenting the pupil’s score of the quiz which they took part In, this result box consists of four aspects.
                </p>
                <ul className="guide_li">
                    <li>Player-This attribute presents the name or the user Id of the player</li>
                    <li>Score-The students percentage quiz score is shown here</li>
                    <li>Passing %- At the time of class creation, the professor needs to set a standard percent, so that is shown here.</li>
                    <li>Status: The status of the course appears as “In Progress” till the end date, once the course is finished it appears as “Completed”.</li>
                </ul>
                <label className="title">Screenshots/steps/guidance</label>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/classdashboard-1.png')} />
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/professor/Gameperf-class.png')} />
            </section>

            <section id="CloseouttheClass">
                <label className="title">Grade the Class</label>
                <p>
                    Report Card:
                    The Report card is another sub-feature in “Course Work Performance” where the professor can see students' quiz details such as the topic, Level they have reached, percentage score, Pass or fail, and the Amount they have earned. For this feature, you just need to click on the name of the student in the Course Work Performance box.
                </p>
                {
                    class_closeout_list_img.map((img, i) => {
                        // return <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/' + img)} />
                    })
                }
                 <img style={{ width: '55%', marginBottom: 20, marginLeft: '20%' }} src={require('./src/images/professor/Report-card-game.png')} />
            </section>

            <h2 style={{fontWeight:'bold', opacity:0.8, marginTop:20}}>Contest</h2>
                <div style={{height:1, border:'0.1px solid rgba(0,0,0,0.2)', width:'100%', marginBottom:10}}></div>

            <section id="Contest">
                <p>The following model depicts a simple flow of interactions that a teacher uses to work with FutureTraders Contest:</p>
                {_renderProfessorContestFm()}
            </section>

            <section id="CreateContest">
                <p>The sections below explain each interaction in detail, including instructions, best practices, and suggestions:</p>
                <label className="title">Create a Contest</label>
                <ul className="guide_li">
                <li>Contest Name: Name your contest.</li>
                    <li>Contest Subject: Choose from several options in the dropdown.</li>
                    <li>Description: Describe your contest. (i.e., Investing or Portfolio Management)</li>
                    <li>Registration Start date: This can be today's date or the earliest date to allow student registration.</li>
                    <li>Registration End Date: The final deadline/date for student registration.</li>
                    <li>Time zone: This is automated for everyone.</li>
                    <li>Number of Participants: The maximum limit of participants who might enroll.</li>
                     <li>Select Game Types: Stocks and/or Bonds.</li>
                    <li>Initial Capital: Initial Balance to start the game.</li>
                    <li>Rounds At Each Level: Choose how many 2 minute rounds you would like the students to play at each difficulty level: We suggest 3 to 5 rounds.</li>
                </ul>
                <label className="title">Screenshots/steps/guidance</label>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Contest-1.PNG')} />
            </section>

            <section id="InviteStudentsContest">
                <label className="title">Invite Participants</label>
                <p>Your link(contest) is created, now you need to invite students for registering, here we have 2 ways to invite</p>
                <ul className="guide_li">
                    
                    <li>After completion of the form, a pop-up box appears on the page where you can enter multiple mail Id’s and send an invitation to those students through a mail.</li>
               
                </ul>
                <img style={{ width: '55%', marginBottom: 20, marginLeft: '20%' }} src={require('./src/images/professor/Invite-participants-popup-contest.png')} />
                <ul className="guide_li">
                    
                    <li> There is also another option where you can copy the URL link and click on the edit icon of your portal and you can paste there and invite students.</li>
                </ul>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/professor/Invite-participants-URL-contest.png')} />

                <label className="title">Invite Participants(Participating)</label>
                <p>Professor and Teacher can also be the Participants of any contest.</p>
                <ul className="guide_li">
                    <li>If the Professor/Teacher/Player receives the invitation mail from the other professors or teachers or players, by clicking on the given link in the mail, he will be directed to the Future Traders webpage with a pop-up message saying “you have successfully joined the contest”.</li>
                    <li>If the Participant gets the URL From other sources, then he should enter the registered mail ID so that he will join the class successfully.</li>
                    <li>Now, by clicking on “My Contests” which is on the top right of the home page, you will be directed to the “Participating Contests” page, where you can find all your contests.</li>
                </ul>

                <label className="title">Screenshots/steps/guidance</label>
                {
                    contest_invite_student_img.map((img, i) => {
                        // return <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/' + img)} />
                    })
                }
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/professor/Contest-participating.png')} />
            </section>

            <section id="MonitorGamePerformanceContest">
                <label className="title">Monitor Game Performance</label>
                <p>When a player secures the standard score which is fixed by the teacher he will be allowed to play the game. </p>
                <p>After the game ends, to perceive  information of the game status you can make use of this feature “Game Performance” </p>
                <p>This attribute consists of three aspects:</p>
                <ul className="guide_li">
                    <li>Player- Here you can see the players’ names</li>
                    <li>Ending balance-  when a player plays the game, after each round the player Gains or losses a certain amount of money, this attribute helps the player in seeing the end balance.</li>
                    <li>Overall status- This is a feature that is related to the end date of the course.</li>
                </ul>
                
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/contestdashboard-1.png')} />
            </section>

            <section id="CloseouttheContest">
                <label className="title">Grade the Contest</label>
                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
                <label className="title">Screenshots/steps/guidance</label>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/professor/Grade-contest.png')} />
            </section>

            <p>Our next set of enhancements will enable professors to author/tailor - curriculum, quizzes, and game simulations. We will be the only platform providing such capabilities.</p>
            <p>The goal of this guide is to support you with all your information and how-to needs. If you have any questions or suggestions, please email us at info@playfuturetraders.com.</p>
        </Fragment>
    }

    const _renderStudentClassFm = () => {
        return <div className="fm">
            {
                student_class_fm.map((item, i) => {
                    if (i === 0) {
                        return <div style={{ position: 'relative', width: 55, height: 45, marginRight: 6, top: -5 }}>
                            <img key={i} style={{ width: 55, height: 55, opacity: 0.8 }} src={require('./src/images/circle_black.png')} />
                            <div style={{ width: 55, height: 55, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, color: '#D1FCF7' }}><a style={{ color: 'white' }} href="#">{item}</a></div>
                        </div>
                    } else if (i === 1) {
                        return <div style={{ position: 'relative', width: 150, height: 45 }}>
                            <img key={i} style={{ width: 150, height: 45 }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><a href="#CreateClass">{item}</a></div>
                        </div>
                    } else if (i === 2) {
                        return <div style={{ position: 'relative', width: 150, height: 160 }}>
                            <img key={i} style={{ width: 100, height: 160 }} src={require('./src/images/Path_3.png')} />
                            <div style={{ position: 'absolute', top: 21, left: 59, display: 'flex', justifyContent: 'center', alignItems: 'center', transform: 'rotate(90deg) scale(0.45)' }}><a href="#InviteStudents"><ArcText class={"arch_text1"} text={item} /></a></div>
                        </div>
                    } else if (i === 3) {
                        return <div style={{ position: 'relative', width: 150, height: 160, top: -47, left: -40 }}>
                            <img key={i} style={{ width: 100, height: 160, transform: 'rotateY(180deg)' }} src={require('./src/images/Path_3.png')} />
                            <div style={{ position: 'absolute', top: 10, left: 46, display: 'flex', justifyContent: 'center', alignItems: 'center', transform: 'rotate(270deg) scale(0.45)' }}><a href="#MonitorGamePerformance"><ArcText class={"arch_text1"} text={item} /></a></div>
                        </div>
                    }
                    else if (i === 4) {
                        return <div style={{ position: 'relative', width: 150, height: 45, left: -95, top: -47 }}>
                            <img key={i} style={{ width: 150, height: 45, transform: 'rotateZ(180deg)' }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><a href="#MoniterStudentslearning">{item}</a></div>
                        </div>
                    }
                    else if (i === 5) {
                        return <div style={{ position: 'relative', width: 150, height: 45, top: -95, left: 65 }}>
                            <img key={i} style={{ width: 150, height: 45 }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><a href="#CloseouttheClass">{item}</a></div>
                        </div>
                    }
                    else if (i === 6) {
                        return <div style={{ position: 'relative', width: 55, height: 45, top: -100, left: 75, marginRight: 6 }}>
                            <img key={i} style={{ width: 55, height: 55, transform: 'rotateZ(180deg)', opacity: 0.8 }} src={require('./src/images/circle_black.png')} />
                            <div style={{ width: 55, height: 55, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, color: '#D1FCF7' }}><a style={{ color: 'white' }} href="#">{item}</a></div>
                        </div>
                    }
                })
            }
        </div>
    }

    const _renderStudentContestFm = () => {
        return <div className="fm">
            {
                professor_contest_fm.map((item, i) => {
                    if (i === 0) {
                        return <div style={{ position: 'relative', width: 55, height: 45, marginRight: 6, top: -5 }}>
                            <img key={i} style={{ width: 55, height: 55, opacity: 0.8 }} src={require('./src/images/circle_black.png')} />
                            <div style={{ width: 55, height: 55, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, color: '#D1FCF7', paddingTop: 5 }}><label>{item}</label></div>
                        </div>
                    } else if (i === 1) {
                        return <div style={{ position: 'relative', width: 150, height: 45 }}>
                            <img key={i} style={{ width: 150, height: 45 }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><label>{item}</label></div>
                        </div>
                    } else if (i === 2) {
                        return <div style={{ position: 'relative', width: 150, height: 45 }}>
                            <img key={i} style={{ width: 150, height: 45 }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><label>{item}</label></div>
                        </div>
                    } else if (i === 3) {
                        return <div style={{ position: 'relative', width: 150, height: 160 }}>
                            <img key={i} style={{ width: 100, height: 160 }} src={require('./src/images/Path_3.png')} />
                            <div style={{ position: 'absolute', top: 12, left: 59, display: 'flex', justifyContent: 'center', alignItems: 'center', transform: 'rotate(90deg) scale(0.45)' }}><ArcText class={"arch_text1"} text={item} /></div>
                        </div>
                    } else if (i === 4) {
                        return <div style={{ position: 'relative', width: 150, height: 45, top: -45, opacity: 0 }}>
                            <img key={i} style={{ width: 150, height: 45, transform: 'rotateZ(180deg)' }} src={require('./src/images/Path_4.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><label>{item}</label></div>
                        </div>
                    } else if (i === 5) {
                        return <div style={{ position: 'relative', width: 55, height: 45, top: -50, marginRight: 6 }}>
                            <img key={i} style={{ width: 55, height: 55, transform: 'rotateZ(180deg)', opacity: 0.8 }} src={require('./src/images/circle_black.png')} />
                            <div style={{ width: 55, height: 55, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, color: '#D1FCF7', paddingTop: 5 }}><label>{item}</label></div>
                        </div>
                    } else if (i === 6) {
                        return <div style={{ position: 'relative', width: 150, height: 45, top: -45 }}>
                            <img key={i} style={{ width: 150, height: 45, transform: 'rotateZ(180deg)' }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><label>{item}</label></div>
                        </div>
                    }
                })
            }
        </div>
    }

    const _renderStudent = () => {
        return <Fragment>
            <label style={{ backgroundColor: '#1A73E8', color: 'white', padding: '10px 6px' }} className="title">Students Guide</label>

            <section id="Class">
                <p> 
                When the student receives an invitation from the Professor or a Teacher through the mail, you will be directed to a page wherein you can sign-up by filling a form, or if you get the link to the game from other sources than you need to enter you mail Id first and then you will be directed to the sign-up page, where you need to provide certain details,
                </p>
                <p><strong>Welcome Students!</strong> You have arrived at the gateway to master FutureTraders.</p>
                <h2 style={{fontWeight:'bold', opacity:0.8, marginTop:20}}>Class</h2>
                <div style={{height:1, border:'0.1px solid rgba(0,0,0,0.2)', width:'100%', marginBottom:10}}></div>
                <p>The following model depicts a simple flow of interactions that a student uses to work with FutureTraders Class:</p>
                {_renderStudentClassFm()}
            </section>

            <section id="CreateClass">
                <p>The sections below explain each interaction in detail, including instructions, best practices, and suggestions:</p>
                <label className="title">Registration</label>
                <p>Registration process after getting invitation link from teacher or professor account to mail. Click the Invitation link and enter the details on the directed page.
             </p>
             <p>  Details to enter:</p>
             <p>Class Information: 
                 <ul className="guide_li">
                     <li>Class Name: The name of the class is automated in this feature.</li>
                 </ul>
                 </p>
            
             <p>Personal Information:
                 <ul className="guide_li">
                     <li>Name: Here you are required to enter your first name and last name respectively.</li>
                     <li>Email: The Email address is registered already, as you will sign with the mail ID.</li>
                     <li>Phone Number: You need to select your country and provide your phone number.</li>
                     <li>School/College: Here you must enter your school or college name.</li>
                 </ul>
             </p>
             <p>Login Information:
                 <ul className="guide_li">
                     <li>Email: The students registered mail address should be entered here.</li>
                     <li>Password: Create a new password to sign up.</li>
                     <li>Confirm Password: Re-enter the same password to confirm it.</li>
                 </ul>
             </p>
             <p>After completing the form, click “SUBMIT” to complete the  registration and check the mail for "Email Confirmation Link".</p>
            
                <label className="title">Screenshots/steps/guidance</label>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Student/Student-Registration.png')} />
              
                {
                    create_class_list_img.map((img, i) => {
                        return <Fragment>
                            {
                                i === 0 ?
                                    <Fragment>
                                        <p></p>
                                       
                                    </Fragment> :
                                    i === 1 ?
                                   
                                        <Fragment>
                                            <p>Email Verification</p>
                                            <ul className="guide_li">
                                                <li>After submitting the form, the student will receive a link to the registered mail ID, by clicking the link the account will be verified.</li>
                                                <li>After completing the form and Email verification a Pop-up message will be displayed saying “You are successfully joined ”, hereafter you will be having a “Student Account”, you can log in with your Mail Id and Password.</li>
                                            </ul>
                                        </Fragment> :
                                        <Fragment>
                                            <p></p>
                                            
                                        </Fragment>
                            }
                            {/* <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/' + img + '.PNG')} /> */}
                        </Fragment>
                    })
                }
                 
            </section>

            <section id="InviteStudents">
                <label className="title">Login Process: </label>
                <p>To login, you need to enter the FT website and click “LOGIN” which is present at the top right of the navigation bar and enter the login details.</p>
                <p>Details to enter: </p>
                <p>
                    <ul lassName="guide_li" >
                        <li>Email: The students registered mail address should be entered here.</li>
                        <li>Password: Enter the password, created at the time of registration.</li>
                    </ul>
                </p>
                <p>After entering all the details, click the “LOGIN” button. Then, you will be directed to the page where all the invited classes/contests are displayed.</p>
                
                <label className="title">Screenshots/steps/guidance</label>
                {
                    invite_class_list_img.map((img, i) => {
                        // return <Fragment>
                        //     {
                        //         i === 0 ?
                        //             <ul className="guide_li"><li>After completion of the form, a pop-up box appears on the page where you can enter multiple mail Id’s and send an invitation to those students through a mail.</li></ul> :
                        //             <ul className="guide_li"><li>There is also another option where you can copy the URL link and click on the edit icon of your portal and you can paste there and invite students.</li></ul>
                        //     }
                        //     {/* <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/' + img + '.PNG')} /> */}
                        // </Fragment>
                    })
                }
                <p>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Student/Login-page.png')} />
                </p>
                
            </section>

            <section id="MoniterStudentslearning">
                <label className="title">My Classes</label>
                <p>Whenever you log in and select “My Classes” from the top right you will be directed to the “Registered classes” page.
                </p>
                <p>
                On this page, you can find your invited classes with general class details such as,
               
                </p>
                <ul lassName="guide_li">
                    <li>Class Name </li>
                    <li>Status of the class</li>
                    <li>FT Play button</li>
                    <li>Class Dates</li>
                    <li>Student Dashboard</li>
                </ul>
                <p>FT Play button, when you click on that button, a table consists of Learning Status and Game Status opens, in which you start your quiz by clicking on the “Start” button. </p>
              
                <label className="title">Screenshots/steps/guidance</label>
                {/* <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/classdashboard-1.png')} /> */}
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Student/My-classes-student.png')} />
                <section>
                
                <label className="title">Curriculum</label>
                <p>Before your quiz begins, there is a curriculum which you must go through, it includes,</p>
                <p>Basic Rules, types of roles in the game, basic strategy for traders, trading on breaking news strategy, calculating P&L to determine Winner and Basic Topics.</p>
                <p> Now,  you can start your quiz by clicking on the Start quiz button and complete it, </p>
                <p>Finally, you will get your quiz result, where you can know whether you have earned the license to play FT game stocks as a trader if you pass you can continue with the game, but if you fail you must give the quiz test again.</p>
                <p>After finishing your game, to know the balance amount you need to go to My classes, select Students DashBoard. </p>
                </section>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Student/curriculum.png')} />
                
               
          
           </section>

            <section id="MonitorGamePerformance">
            <h2 style={{fontWeight:'bold', opacity:0.8, marginTop:20}}>Contest</h2>
                <div style={{height:1, border:'0.1px solid rgba(0,0,0,0.2)', width:'100%', marginBottom:10}}></div>
                <label className="title">My Contests</label>
                <p>If the student receives the invitation mail from the professor or teacher, by clicking on the given in the mail, he will be directed to the Future Traders webpage with a pop-up message saying “you have successfully joined the contest”.</p>
                <p>If the student gets the URL from other sources, then he needs to enter the same mail ID which is registered with the contest and then he is joined successfully.</p>
                <p>Whenever you log in and select “My Contests” from the top right you will be directed to the “Registered contests” page.</p>
                <p>On this page, you can find your invited contests with general contest details such as,</p>
                <ul>
                    <li>Contest Name </li>
                    <li>Status of the Contest</li>
                    <li>FT Play Button</li>
                    <li>Contest Dates</li>
                    <li>Performance Dashboard</li>
                </ul>
                <label className="title">Screenshots/steps/guidance</label>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Student/My-contest-student.png')} />
            </section>

            <section id="CloseouttheClass">
                <label className="title">Dashboard</label>
                <p> <label>Student Dashboard</label></p>
               
                <p>This Feature Shows all the information about your class, a table which shows Game vs Quiz earnings.</p>
                <p>
                    <ul>
                        <li> Current Status: It shows whether your course is in progress or completed.</li>
                        <li>Best Performance: The students whose performance is best in the Quiz and Game will be shown here.</li>
                        <li>Performance at each Level: It shows your performance at each level in support of a line graph.</li>
                    </ul>
                   </p>
                <p>
                    <label>Course Work Results</label>
                    <ul>
                        <li>Topics: It shows the topic of the game and quiz</li>
                        <li>Level: We will have different levels, this attribute will show the level of the student.</li>
                        <li>Correct Answer: The number of correct questions is shown here.</li>
                        <li>Incorrect Answer: The number of incorrect questions is shown here.</li>
                        <li>Score: The total score of the student at that level is shown here.</li>
                        <li>Passing Score: The score is fixed by the professor or teacher.</li>
                        <li>Amount Earned: The total amount which you earned for all the correct answers is shown here.</li>
                        <li>Result: Have you passed or fail is shown here.</li>
                    </ul>
                </p>
                <p>
                <label>Game Performance</label>
                <ul>
                    <li>Game Type: The type of game is shown here for instance it shows stocks if the game type is stocks.</li>
                    <li>Level: Here the level of the students’ game is shown.</li>
                    <li>Opening Balance: The student will start the game with 0$, which is shown here.</li>
                    <li>Ending balance: When a student plays the game, after each round the player Gains or losses a certain amount of money, this attribute helps the player in seeing the end balance.</li>
                    <li>Status: The status of the course appears as “In Progress” till the end date, once the course is finished it appears as “Completed”.</li>
                    
                </ul>
                </p>
                <p>FT Play button, when you click on that button, a table consists of Game Console opens, in which you start your game by clicking on the “Start” button. </p>
                <p>This Game Console table contains information about the type of the game, and different levels of the game such as Trader, Market Maker, Advanced Trader, Advanced Market Maker.</p>
                <label className="title">Screenshots/steps/guidance</label>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Student/Student-dashboard-myclasses-All-1.png')} />
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Student/Student-dashboard-myclasses-All-2.png')} />
                <p><label>Performance Dashboard</label></p>
                <p>This feature shows basic information like Host, Subject, Game Type, Initial Capital, Registration Date, Trading Date, Number of Participants, Rounds at each level.</p>
                <p>
                    <label>Average Performance at Each Level</label>
                    <p>When the student finishes his game, he can make use of this attribute to check his average performance at each level in a line graph.</p>
                    <ul>
                        <li>Game Type: The type of game is shown here for instance it shows stocks if the game type is stocks.</li>
                        <li>Level: Here the level of the students’ game is shown.</li>
                        <li>Opening Balance: The student will start the game with 0$, which is shown here.</li>
                        <li>Round: The number of rounds which he reached is shown here.</li>
                        <li>Ending balance: when a student plays the game, after each round the player Gains or losses a certain amount of money, this attribute helps the player in seeing the end balance.</li>
                        <li>Status: The status of the course appears as “In Progress” till the end date, once the course is finished it appears as “Completed”.</li>
                    </ul>
                </p>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Student/Performance-dashboard-contest-all.png')} />
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Student/course-work-classes.png')} />
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Student/game-performance.png')} />

                {
                    class_closeout_list_img.map((img, i) => {
                        // return <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/' + img)} />
                    })
                }
            </section>

            {/* <section id="Contest">
                <p>The following model depicts a simple flow of interactions that a professor uses to work with FutureTraders Contest:</p>
                {_renderProfessorContestFm()}
            </section> */}

            {/* <section id="CreateContest">
                <p>The sections below explain each interaction in detail, including instructions, best practices, and suggestions:</p>
                <label className="title">Create a Contest</label>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                <label className="title">Screenshots/steps/guidance</label> */}
                {/* <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Contest-1.PNG')} /> */}
            {/* </section> */}
{/* 
            <section id="InviteStudentsContest">
                <label className="title">Invite Students</label>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                <label className="title">Screenshots/steps/guidance</label>
                {
                    contest_invite_student_img.map((img, i) => {
                        // return <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/' + img)} />
                    })
                }
            </section> */}

            {/* <section id="MonitorGamePerformanceContest">
                <label className="title">Monitor Game Perf</label>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
                {/* <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/contestdashboard-1.png')} /> */}
            {/* </section> */}

            {/* <section id="CloseouttheContest">
                <label className="title">Close out the Contest</label>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                <label className="title">Screenshots/steps/guidance</label> */}
                {/* <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/contestdashboard-2.png')} /> */}
            {/* </section> */}

            {/* <p>Our next set of enhancements will enable professors to author/tailor - curriculum, quizzes, and game simulations. We will be the only platform providing such capabilities.</p> */}
            <p>The goal of this guide is to support you with all your information and how-to needs. If you have any questions or suggestions, please email us at info@playfuturetraders.com.</p>
        </Fragment>
    }

    const _renderPlayerClassFm = () => {
        return <div className="fm">
            {
                professor_class_fm.map((item, i) => {
                    if (i === 0) {
                        return <div style={{ position: 'relative', width: 55, height: 45, marginRight: 6, top: -5 }}>
                            <img key={i} style={{ width: 55, height: 55, opacity: 0.8 }} src={require('./src/images/circle_black.png')} />
                            <div style={{ width: 55, height: 55, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, color: '#D1FCF7', paddingTop: 5 }}><label>{item}</label></div>
                        </div>
                    } else if (i === 1) {
                        return <div style={{ position: 'relative', width: 150, height: 45 }}>
                            <img key={i} style={{ width: 150, height: 45 }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><label>{item}</label></div>
                        </div>
                    } else if (i === 2) {
                        return <div style={{ position: 'relative', width: 150, height: 45 }}>
                            <img key={i} style={{ width: 150, height: 45 }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><label>{item}</label></div>
                        </div>
                    } else if (i === 3) {
                        return <div style={{ position: 'relative', width: 150, height: 160 }}>
                            <img key={i} style={{ width: 100, height: 160 }} src={require('./src/images/Path_3.png')} />
                            <div style={{ position: 'absolute', top: 12, left: 59, display: 'flex', justifyContent: 'center', alignItems: 'center', transform: 'rotate(90deg) scale(0.45)' }}><ArcText class={"arch_text1"} text={item} /></div>
                        </div>
                    } else if (i === 4) {
                        return <div style={{ position: 'relative', width: 55, height: 45, top: -50, marginRight: 6 }}>
                            <img key={i} style={{ width: 55, height: 55, transform: 'rotateZ(180deg)', opacity: 0.8 }} src={require('./src/images/circle_black.png')} />
                            <div style={{ width: 55, height: 55, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, color: '#D1FCF7', paddingTop: 5 }}><label>{item}</label></div>
                        </div>
                    } else if (i === 5) {
                        return <div style={{ position: 'relative', width: 150, height: 45, top: -45 }}>
                            <img key={i} style={{ width: 150, height: 45, transform: 'rotateZ(180deg)' }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><label>{item}</label></div>
                        </div>
                    } else if (i === 6) {
                        return <div style={{ position: 'relative', width: 150, height: 45, top: -45 }}>
                            <img key={i} style={{ width: 150, height: 45, transform: 'rotateZ(180deg)' }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><label>{item}</label></div>
                        </div>
                    }
                })
            }
        </div>
    }

    const _renderPlayerContestFm = () => {
        return <div className="fm">
            {
                player_contest_fm.map((item, i) => {
                    if (i === 0) {
                        return <div style={{ position: 'relative', width: 55, height: 45, marginRight: 6, top: -5 }}>
                            <img key={i} style={{ width: 55, height: 55, opacity: 0.8 }} src={require('./src/images/circle_black.png')} />
                            <div style={{ width: 55, height: 55, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, color: '#D1FCF7' }}><a style={{ color: 'white' }} href="#">{item}</a></div>
                        </div>
                    } else if (i === 1) {
                        return <div style={{ position: 'relative', width: 150, height: 45 }}>
                            <img key={i} style={{ width: 150, height: 45 }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><a href="#CreateContest">{item}</a></div>
                        </div>
                    } else if (i === 2) {
                        return <div style={{ position: 'relative', width: 150, height: 160 }}>
                            <img key={i} style={{ width: 100, height: 160 }} src={require('./src/images/Path_3.png')} />
                            <div style={{ position: 'absolute', top: -10, left: 59, display: 'flex', justifyContent: 'center', alignItems: 'center', transform: 'rotate(90deg) scale(0.45)' }}><a href="#InviteStudentsContest"><ArcText class={"arch_text1"} text={item} /></a></div>
                        </div>
                    } else if (i === 3) {
                        return <div style={{ position: 'relative', width: 150, height: 160, top: -47, left: -40 }}>
                            <img key={i} style={{ width: 100, height: 160, transform: 'rotateY(180deg)' }} src={require('./src/images/Path_3.png')} />
                            <div style={{ position: 'absolute', top: 2, left: 42, display: 'flex', justifyContent: 'center', alignItems: 'center', transform: 'rotate(270deg) scale(0.45)' }}><a href="#CloseouttheContest"><ArcText class={"arch_text1"} text={item} /></a></div>
                        </div>
                    }
                    else if (i === 4) {
                        return <div style={{ position: 'relative', width: 150, height: 45, top: -45, left: -95 }}>
                            <img key={i} style={{ width: 150, height: 45, transform: 'rotateZ(180deg)' }} src={require('./src/images/Path_2.png')} />
                            <div style={{ width: 150, height: 45, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 13 }}><a href="#MonitorGamePerformanceContest">{item}</a></div>
                        </div>
                    }
                    else if (i === 5) {
                        return <div style={{ position: 'relative', width: 55, height: 45, top: 62, left: -232, marginRight: 6 }}>
                            <img key={i} style={{ width: 55, height: 55, transform: 'rotateZ(180deg)', opacity: 0.8 }} src={require('./src/images/circle_black.png')} />
                            <div style={{ width: 55, height: 55, position: 'absolute', top: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, color: '#D1FCF7' }}><a style={{ color: 'white' }} href="#">{item}</a></div>
                        </div>
                    }
                })
            }
        </div>
    }

    const _renderPlayer = () => {
        return <Fragment>
            <label style={{ backgroundColor: '#1A73E8', color: 'white', padding: '10px 6px' }} className="title">Players Guide</label>

            <section id="Class">
                <p>
                When a participant registers on the home page, he is required to fill up all the details such as,</p>
                <p><strong>Welcome Players!</strong> You have arrived at the gateway to master FutureTraders.</p>
                {/* <p>The following model depicts a simple flow of interactions that a player uses to work with FutureTraders Class:</p>
                {_renderProfessorClassFm()} */}
            </section>

            {/* <section id="CreateClass">
                <p>The sections below explain each interaction in detail, including instructions, best practices, and suggestions:</p>
                <label className="title">Create a Class</label>
                <p>First, register as a professor on the home page, then immediately you will be directed to a page where you will find an option as “create a class” when you click on it you are allowed to another page called as Class Registration which requires basic information like</p>
                <label className="title">Screenshots/steps/guidance</label>
                {
                    create_class_list_img.map((img, i) => {
                        return <Fragment>
                            {
                                i === 0 ?
                                    <Fragment>
                                        <p></p>
                                        <ul className="guide_li">
                                            <li>Class Name: This can be by your own choice or else you can name your class with the running season.</li>
                                            <li>Class Subject: Where you have several options to choose from your choice.</li>
                                            <li>Description: This is a small brief of your class.</li>
                                            <li>Time zone: This is generally automated for everyone.</li>
                                            <li>Number of Students: This is to know a certain figure that how many students you need in your class on the whole.</li>
                                            <li>Academic Level: In which you can choose the level as Post Graduation & above or others as your choice.</li>
                                            <li>Select Game Types: Here you will be provided two types of games you need to choose according to your subject.</li>
                                        </ul>
                                    </Fragment> :
                                    i === 1 ?
                                        <Fragment>
                                            <p>After entering all the details in the attributes, click the “NEXT” button by which you will enter  the 2nd form of registration which is curriculum configuration in which you will have attributes like,</p>
                                            <ul className="guide_li">
                                                <li>Registration Start date: This is where you are required to select the starting date for student registrations.</li>
                                                <li>Registration End Date: And then here there should be an end date for student registrations.</li>
                                                <li>Trading Date: It requires the start date and the end date of the course.</li>
                                                <li>Passing %: Professor needs to provide a Standard Pass Percentage for students.</li>
                                                <li> No. of attempts: Here you can set a certain number of attempts by your choice for students, to secure the standard percentage provided previously.</li>
                                                <li>Amount Awarded (Per Question): In this Attribute, You have to select the amount by which students will be awarded after the Quiz.</li>
                                            </ul>
                                        </Fragment> :
                                        <Fragment>
                                            <p>After entering all the details, you are allowed to complete the Game Configuration, which includes</p>
                                            <ul className="guide_li">
                                                <li>Rounds of each level: In this, you can arrange rounds on your own choice.</li>
                                                <li>Minimum Balance benchmark: Here you can set a certain amount of money as a benchmark for students.</li>
                                                <li>Extra Credits: Here you can opt a certain percentage for extra credits to the Top 5 Performers.</li>
                                            </ul>
                                        </Fragment>
                            }
                            <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/' + img + '.PNG')} />
                        </Fragment>
                    })
                }
            </section> */}

            {/* <section id="InviteStudents">
                <label className="title">Invite Students</label>
                <p>Finally, your link is created, now you need to invite students for registering, here we have 2 ways to invite</p>
                <label className="title">Screenshots/steps/guidance</label>
                {
                    invite_class_list_img.map((img, i) => {
                        return <Fragment>
                            {
                                i === 0 ?
                                    <ul className="guide_li"><li>After completion of the form, a pop-up box appears on the page where you can enter multiple mail Id’s and send an invitation to those students through a mail.</li></ul> :
                                    <ul className="guide_li"><li>There is also another option where you can copy the URL link and click on the edit icon of your portal and you can paste there and invite students.</li></ul>
                            }
                            <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/' + img + '.PNG')} />
                        </Fragment>
                    })
                }
            </section> */}

            {/* <section id="MoniterStudentslearning">
                <label className="title">Moniter Students learning</label>
                <p>This is where you can check the progress of course by observing how many students attended the course, course In progress, course completed through a pie chart.
                Apart from this class dashboard is used to see the Top 5 students in a column chart, Coursework Results and, Game Results in a pie chart, Average Performance in a line graph, All students performance in a column graph, lastly, coursework performance and Game performance in a tabular form.
                </p>
                <label className="title">Screenshots/steps/guidance</label>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/classdashboard-1.png')} />
            </section> */}

            {/* <section id="MonitorGamePerformance">
                <label className="title">Monitor Game Perf</label>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                <label className="title">Screenshots/steps/guidance</label>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/classdashboard-1.png')} />
            </section> */}

            {/* <section id="CloseouttheClass">
                <label className="title">Close out the Class</label>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                {
                    class_closeout_list_img.map((img, i) => {
                        return <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/' + img)} />
                    })
                }
            </section> */}

            {/* <section id="Contest">
                <p>The following model depicts a simple flow of interactions that a professor uses to work with FutureTraders Contest:</p>
                {_renderProfessorContestFm()}
            </section> */}

            {/* <section id="CreateContest">
                <p>The sections below explain each interaction in detail, including instructions, best practices, and suggestions:</p>
                <label className="title">Create a Contest</label>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                <label className="title">Screenshots/steps/guidance</label>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Contest-1.PNG')} />
            </section> */}

            {/* <section id="InviteStudentsContest">
                <label className="title">Invite Students</label>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                <label className="title">Screenshots/steps/guidance</label>
                {
                    contest_invite_student_img.map((img, i) => {
                        return <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/' + img)} />
                    })
                }
            </section> */}

            {/* <section id="MonitorGamePerformanceContest">
                <label className="title">Monitor Game Perf</label>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/contestdashboard-1.png')} />
            </section> */}

            {/* <section id="CloseouttheContest">
                <label className="title">Close out the Contest</label>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                <label className="title">Screenshots/steps/guidance</label>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/contestdashboard-2.png')} />
            </section> */}
             <section id="Contest">
             <h2 style={{fontWeight:'bold', opacity:0.8, marginTop:20}}>Contest</h2>
                <div style={{height:1, border:'0.1px solid rgba(0,0,0,0.2)', width:'100%', marginBottom:10}}></div>
                <p>The following model depicts a simple flow of interactions that a professor uses to work with FutureTraders Contest:</p>
                {_renderPlayerContestFm()}
            </section>

            <section id="CreateContest">
                <p>The sections below explain each interaction in detail, including instructions, best practices, and suggestions:</p>
                <label className="title">Create a Contest</label>
                <ol>
                    <li>1) Register as a professor on the home page.</li>
                    <li>2) You will be directed to  “Create a Class”  (click link)</li>
                    <li>3) You will be directed to Class Registration to input the following information:</li>
                </ol>
                 
                <ul className="guide_li">
                    <li>Contest Name: Name your contest.</li>
                    <li>Contest Subject: Choose from several options in the dropdown.</li>
                    <li>Description: Describe your contest. (i.e., Investing or Portfolio Management)</li>
                    <li>Registration Start date: This can be today's date or the earliest date to allow student registration.</li>
                    <li>Registration End Date: The final deadline/date for student registration.</li>
                    <li>Time zone: This is automated for everyone.</li>
                    <li>Number of Participants: The maximum limit of participants who might enroll.</li>
                     <li>Select Game Types: Stocks and/or Bonds</li>
                    <li>Initial Capital: Initial Balance to start the game</li>
                    <li>Rounds At Each Level: Choose how many 2 minute rounds you would like the students to play at each difficulty level: We suggest 3 to 5 rounds.</li>
                </ul>
                <label className="title">Screenshots/steps/guidance</label>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Contest-1.PNG')} />
            </section>

            <section id="InviteStudentsContest">
                <label className="title">Invite Participants(Hosted)</label>
                <p>Your link(contest) is created and you can invite students to register using either of the following choices:</p>
                <ul className="guide_li">
                    <li>After completion of the form, You can use the popup box and enter multiple email addresses to send an invitation.</li>
                </ul>
                <img style={{ width: '50%',alignItems:'center', marginBottom: 40, marginLeft: '27%' }} src={require('./src/images/Player/Invite-participants-popup-contest.png')} />

                <ul className="guide_li">
                   
                    <li> There is also another option where you can copy the URL link and click on the edit icon of your portal and you can paste and invite students. This can be easily used as a link in Blackboard or whichever educational platform you use.</li>
                </ul>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Player/Invite-participants-URL-contest.png')} />

                <label className="title">Invite Participants(Participating)</label>
                <p>Professor and Teacher can also be the Participants of any contest.</p>
                <ul className="guide_li">
                    <li>If the Professor/Teacher/Player receives the invitation mail from the other professors or teachers or players, by clicking on the given link in the mail, he will be directed to the Future Traders webpage with a pop-up message saying “you have successfully joined the contest”.</li>
                    <li>If the Participant gets the URL From other sources, then he should enter the registered mail ID so that he will join the class successfully.</li>
                    <li>Now, by clicking on “My Contests” which is on the top right of the home page, you will be directed to the “Participating Contests” page, where you can find all your contests.</li>
                </ul>
                
                <label className="title">Screenshots/steps/guidance</label>
                {
                    contest_invite_student_img.map((img, i) => {
                        // return <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/' + img)} />
                    })
                }
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Player/My-contests-palyer.png')} />
            </section>

            <section id="MonitorGamePerformanceContest">
                <label className="title">Monitor Game Performance</label>
                <p>When a player secures the standard score which is fixed by the professor he will be allowed to play the game. </p>
                <p>After the game ends, to perceive  information of the game status you can make use of this feature “Game Performance” </p>
                <p>This attribute consists of three aspects:</p>
                <ul className="guide_li">
                    <li>Player- Here you can see the players’ names.</li>
                    <li>Ending balance-  when a player plays the game, after each round the player Gains or losses a certain amount of money, this attribute helps the player in seeing the end balance.</li>
                    <li>Overall status- This is a feature that is related to the end date of the course.</li>
                </ul>
                
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/contestdashboard-1.png')} />
            </section>

            <section id="CloseouttheContest">
               <p><label className="title">Grade the Contest</label></p> 
                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
                <label className="title">Screenshots/steps/guidance</label>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/contestdashboard-2.png')} />
            </section>

            <section id="myGames">
            <h2 style={{fontWeight:'bold', opacity:0.8, marginTop:20}}>Game</h2>
                <div style={{height:1, border:'0.1px solid rgba(0,0,0,0.2)', width:'100%', marginBottom:10}}></div>
                <p><label className="title">My Games</label> </p>
                <p>This is a feature that is located on the top right of the page, where you can find your registered games or you can also play a new game.</p>
                <p>Here, you can find certain details in your registered games like Game Name, Game Status, participating dates, and the Game DashBoard.</p>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Player/My-contests-palyer.png')} />

                <p><label className="title">Game Dashboard</label></p>
                <p>This feature shows basic information like Host, Subject, Game Type, Initial Capital, Registration Date, Trading Date, Number of Participants, Rounds at each level, and description of the game.</p>
                <p>
                    <label>Average Performance at Each Level</label>
                    <p>When the participant finishes his game, he can make use of this attribute to check his average performance at each level in a line graph.</p>
                    <p>Game Performance</p>
                    <ul>
                        <li>Game Type: The type of game is shown here for instance it shows stocks if the game type is stocks.</li>
                        <li>Level: Here the level of the students’ game is shown.</li>
                        <li>Opening Balance: The student will start the game with 0$, which is shown here.</li>
                        <li>Round: The number of rounds reached is shown here.</li>
                        <li>Ending balance: When a student plays the game, after each round the player Gains or losses a certain amount of money, this attribute helps the player in seeing the end balance.</li>
                        <li>Status: The status of the course appears as “In Progress” till the end date, once the course is finished it appears as “Completed”.</li>
                    </ul>
                </p>
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Player/contest-dashboard-all-player.png')} />
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Player/contest-dashboard-all-2-player.png')} />
                <img style={{ width: '80%', marginBottom: 20, marginLeft: '10%' }} src={require('./src/images/Player/Game-perf-contest-dashboard.png')} />

            </section>

            

            <p>Our next set of enhancements will enable professors to author/tailor - curriculum, quizzes, and game simulations. We will be the only platform providing such capabilities.</p>
            <p>The goal of this guide is to support you with all your information and how-to needs. If you have any questions or suggestions, please email us at info@playfuturetraders.com.</p>
        </Fragment>
    }

    const _renderProfessorNavigationBar = () => {
        return <ul>
            {
                activePage !== "player" &&
                <Fragment>
                    <li><a href="#Class">Class</a></li>
                        <ul>
                            <li><a href="#CreateClass">{activePage === "student" ? "Registartion":"Create Class"}</a></li>
                            <li><a href="#InviteStudents">{activePage === "student" ? "Login Process":"Invite Students"}</a></li>
                            <li><a href="#MoniterStudentslearning">{activePage !== "student" ? "Monitor Students Learning" : "Monitor Learning"}</a></li>
                            <li><a href="#MonitorGamePerformance">{activePage !== "student" ? "Monitor Game Performance":"Game Performance"}</a></li>
                            <li><a href="#CloseouttheClass">{activePage === "student" ? "Dashboard":"Grade the Class"}</a></li>
                        </ul>
                </Fragment>
            }
            {
                activePage !== "student" &&
                <Fragment>
                    <li><a href="#Contest">Contest</a></li>
                    <ul>
                        <li><a href="#CreateContest">Create Contest</a></li>
                        <li><a href="#InviteStudentsContest">Invite Participants</a></li>
                        <li><a href="#MonitorGamePerformanceContest">Monitor Game Performance</a></li>
                        <li><a href="#CloseouttheContest">{activePage === "student" ?"Grade the Contest":"Close the Contest"}</a></li>
                    </ul>
                </Fragment>
            }
        </ul>
    }

    return <div className="_help">
        {/* header */}
        <div>
            <img
                src={ftlogo}
                width="33px"
                height="25px"
                alt="image"
            />
            <h3>FutureTraders Interactive User Guide</h3>
            {
                pages.map((cPage, i) => {
                    return <a href={"/guide?page=" + cPage.toLowerCase()} style={activePage === cPage.toLowerCase() ? { fontSize: 16, opacity: 1 } : {}} key={i}>{cPage}</a>
                })
            }
        </div>
        {/* container */}
        <div>
            {/* content */}
            <div>
                {
                    activePage === 'professor' ? _renderProfessor() :
                        activePage === 'teacher' ? _renderTeacher() :
                            activePage === 'student' ? _renderStudent() :
                                activePage === 'player' ? _renderPlayer() : null
                }
            </div>
            {/* right navbar */}
            <div>
                {
                    activePage === 'professor' ? _renderProfessorNavigationBar() :
                        activePage === 'teacher' ? _renderProfessorNavigationBar() :
                            activePage === 'student' ? _renderProfessorNavigationBar() :
                                activePage === 'player' ? _renderProfessorNavigationBar() : null
                }
            </div>
        </div>
    </div>
}