import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router';
// icons
import { AiOutlineCopyright } from 'react-icons/ai';
import Config from "../config/Config";

export default ({ user }) => {
    const [topStudents, setTopStudents] = useState([]);
    const [topStudentsForContest, setTopStudentsForContest] = useState([]);
    const location = useLocation();

    useEffect(() => {
        fetch("https://us-central1-playfuturetraders.cloudfunctions.net/getTopStudents/class/5", {
            method: "GET",
            headers: {
                'app_name': Config.APP_NAME
            }
        }).then((res) => res.json()).then((res) => setTopStudents(sts => [...sts, ...res]));
        fetch("https://us-central1-playfuturetraders.cloudfunctions.net/getTopStudents/contest/5", {
            method: "GET",
            headers: {
                'app_name': Config.APP_NAME
            }
        }).then((res) => res.json()).then((res) => setTopStudentsForContest(sts => [...sts, ...res]));

        setInterval(() => {
            setTopStudents([]);
            fetch("https://us-central1-playfuturetraders.cloudfunctions.net/getTopStudents/class/5", {
                method: "GET",
                headers: {
                    'app_name': Config.APP_NAME
                }
            }).then((res) => res.json()).then((res) => setTopStudents(sts => [...sts, ...res]));
        }, 40000);
        setInterval(() => {
            setTopStudentsForContest([]);
            fetch("https://us-central1-playfuturetraders.cloudfunctions.net/getTopStudents/contest/5", {
                method: "GET",
                headers: {
                    'app_name': Config.APP_NAME
                }
            }).then((res) => res.json()).then((res) => setTopStudentsForContest(sts => [...sts, ...res]));
        }, 40000);
    }, []);

    return <div className="footer-bottom" style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="container footer-height" style={{ height: 25 }}>
            <div style={{ flex: 1, display: 'flex', alignItems: 'center', position: 'relative', top: -10 }}>
                {
                    topStudents.length > 0 ?
                        <marquee behavior="scroll" direction="left">
                            <p style={{ color: '#38AEFF', display: 'inline', fontSize: 12 }}>TOP 5 STUDENTS:</p>
                            {
                                topStudents.map((x, i) => <p style={{ marginRight: 20, marginLeft: 20, display: 'inline', color: '#FF6D00', fontSize: 12, paddingBottom: 10 }} key={i}>{Object.values(x)[0][1].toString().toUpperCase() + "(" + Object.values(x)[0][0].toString().toLowerCase() + ")"}</p>)
                            }
                            <p style={{ color: '#38AEFF', display: 'inline', fontSize: 12 }}>TOP 5 CONTESTANTS:</p>
                            {
                                topStudentsForContest.map((x, i) => <p style={{ marginRight: 20, marginLeft: 20, display: 'inline', color: '#FF6D00', fontSize: 12, paddingBottom: 10 }} key={i}>{Object.values(x)[0][1].toString().toUpperCase() + "(" + Object.values(x)[0][0].toString().toLowerCase() + ")"}</p>)
                            }
                        </marquee> : null
                }
            </div >
        </div>
        {
            location.pathname == "/" ?
                <div style={{ height: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <AiOutlineCopyright style={{ marginRight: 3, marginBottom: 6 }} />
                    <p>playfuturetraders.com. All rights reserved. Powered by: Techgrity</p>
                </div> : null
        }
        {/* <p style={{ color: 'white', fontSize: 12, opacity: 0.6, fontWeight: 400, height:10 }}>Powered by: <a style={{ color: '#00BBE7', textDecoration: 'underline' }}>Techgrity</a></p> */}
    </div>
}