import firebase from "firebase";

const firebaseApp = firebase.initializeApp({
  // develop
  // apiKey: "AIzaSyC7SrTDnMQGPLtrxEyNErTNG1RhMaFRyh8",
  // authDomain: "playftdev.firebaseapp.com",
  // databaseURL: "https://playftdev.firebaseio.com",
  // projectId: "playftdev",
  // storageBucket: "playftdev.appspot.com",
  // messagingSenderId: "540376907060",
  // appId: "1:540376907060:web:db6dc55d23510e0f"
  // staging
  // apiKey: "AIzaSyC2Bv1GXdOQXKQVxOg-QmiFIF-lgJKzmjI",
  // authDomain: "playftstaging.firebaseapp.com",
  // databaseURL: "https://playftstaging.firebaseio.com",
  // projectId: "playftstaging",
  // storageBucket: "playftstaging.appspot.com",
  // messagingSenderId: "793818625064",
  // appId: "1:793818625064:web:6f96107d21fd878ac5311d"
  //production
  apiKey: "AIzaSyAXGz3okgHwgIha0y_OGDBLu4eWfkNFDrI",
  authDomain: "playfuturetraders.firebaseapp.com",
  databaseURL: "https://playfuturetraders.firebaseio.com",
  projectId: "playfuturetraders",
  storageBucket: "playfuturetraders.appspot.com",
  messagingSenderId: "317545954255",
  appId: "1:317545954255:web:ecce76d41a40801c"

  // ft odu
  // apiKey: "AIzaSyBeE3hezF59Bk8e5wKDCx5MTaspaMVxKkQ",
  // authDomain: "ft-odu.firebaseapp.com",
  // databaseURL: "https://ft-odu.firebaseio.com",
  // projectId: "ft-odu",
  // storageBucket: "ft-odu.appspot.com",
  // messagingSenderId: "698696315763",
  // appId: "1:698696315763:web:d78968993e0ecc42434b06",
  // measurementId: "G-TKK87REEN9"
});

export const storage = firebase.storage();
export const auth = firebase.auth();
export const firestore = firebaseApp.firestore();
export const database = firebaseApp.database();
